<template>
  <section class="container ml-[115px] max-w-[1500px] text-left device-page">
    <div class="flex justify-center" v-if="loading && !errorMessage">
      <LoadingSpinner />
    </div>
    <Alert variant="red" icon="exclamation" v-if="errorMessage" class="mx-5">{{ errorMessage }}</Alert>
    <DevicePageHeader v-if="!loading" :device="device" @deviceUpdated="deviceUpdated" />
    <hr class="mb-6" />
    <div class="px-4">
      <DeviceUsersV2 v-if="!loading" :device="device" />
    </div>
  </section>
</template>

<script>
import DevicePageHeader from '@/components/DevicePageHeader';
import DeviceUsersV2 from '@/components/DeviceUsersV2';
import Alert from '@/components/ui/Alert';
import LoadingSpinner from '@/components/rbComponents/LoadingSpinner.vue';

import DeviceServiceV2 from '@/lib/device-service-v2';

export default {
  name: 'Device',
  components: {
    Alert,
    DevicePageHeader,
    DeviceUsersV2,
    LoadingSpinner
  },
  data() {
    return {
      deviceId: this.$route.params.id,
      device: null,
      errorMessage: null,
      loading: true
    };
  },
  async mounted() {
    await this.readDevice();
  },
  methods: {
    async readDevice() {
      try {
        this.device = await DeviceServiceV2.readDevice(this.deviceId);
      } catch (error) {
        this.errorMessage = this.parseError(error);
      } finally {
        this.loading = false;
      }
    },
    async deviceUpdated() {
      await this.readDevice();
    }
  }
};
</script>
