<template>
  <GenericTable :columns="columns" :data="data" :title="title" class="device-users">
    <template #status="{ row }">
      <b-badge v-if="row.deleted" variant="dark">Deleted</b-badge>
      <b-badge v-else-if="row.disabled" variant="warning">Disabled</b-badge>
      <b-badge v-else-if="row.frozen" variant="danger">Frozen</b-badge>
      <b-badge v-else-if="!row.emailVerified" variant="warning">Unverified</b-badge>
      <b-badge v-else variant="success">Active</b-badge>
    </template>
  </GenericTable>
</template>

<script>
import GenericTable from '@/components/GenericTable.vue';
import UserServiceV2 from '@/lib/user-service-v2';
export default {
  components: {
    GenericTable
  },
  props: {
    device: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      columns: [
        {
          name: 'username',
          label: 'Name'
        },
        {
          name: 'email',
          label: 'Email'
        },
        {
          name: 'status',
          label: 'Status'
        }
      ],
      data: [],
      title: 'Device Users'
    };
  },
  mounted() {
    this.getDeviceUsers();
  },
  methods: {
    async getDeviceUsers() {
      const response = await UserServiceV2.listUsers({
        organizationId: this.device.organizationId,
        pageSize: 100
      });
      //   Convert the roles array into a string and combine first and last names into a single username
      const tableData = response.data.map((user) => ({
        ...user,
        username: user.firstName + ' ' + user.lastName
      }));
      this.data = tableData;
    }
  }
};
</script>
<style lang="scss">
.device-users {
  th {
    width: auto !important;
  }
}
</style>
