<template>
  <div class="p-5 mb-4">
    <div class="flex flex-row font-normal text-xs">
      <div v-if="getDeviceImage()"><img :src="getDeviceImage()" alt="" width="340" height="185" /></div>
      <div class="flex flex-col pt-5 space-y-20">
        <div class="flex flex-col">
          <div class="flex flex-row align-items-center">
            <h6 class="font-header font-bold ml-6 text-2xl">
              {{ device.name }}
            </h6>
            <EditDeviceModal v-if="device.id" :deviceId="device.id" @deviceUpdated="deviceUpdated" class="ml-4" />
          </div>
          <StatusBadge :status="device.active" v-if="device.active !== null" class="ml-6" />
        </div>
        <section class="flex flex-row ml-6 mt-1">
          <div>
            <p class="font-header font-bold">Organization</p>
            <p>{{ device.organizationName }}</p>
          </div>
          <div class="ml-6">
            <p class="font-header font-bold">Unique Identifier</p>
            <p>{{ device.serialNumber }}</p>
          </div>
          <div class="ml-6">
            <p class="font-header font-bold">Last Synced</p>
            <p>{{ device.lastSynced ? `${elapsedTimeAsRelative(device.lastSynced)} ago` : 'Never' }}</p>
          </div>
          <div class="ml-6">
            <p class="font-header font-bold">Environment</p>
            <p>{{ device.info.environmentUrl || 'Unknown' }}</p>
          </div>
          <div class="ml-6">
            <p class="font-header font-bold">APK Version</p>
            <p>{{ device.info.apkVersion || 'Unknown' }}</p>
          </div>
          <div class="ml-6">
            <p class="font-header font-bold">Web Version</p>
            <p>{{ device.info.webAppCommitHash || 'Unknown' }}</p>
          </div>
          <div class="ml-6">
            <p class="font-header font-bold">Deployment Group</p>
            <p>{{ device.deploymentGroup || 'Unknown' }}</p>
          </div>
          <div class="ml-6">
            <p class="font-header font-bold">Deployment Url</p>
            <p>{{ device.deploymentUrl || 'Unknown' }}</p>
          </div>
        </section>
      </div>
    </div>
  </div>
</template>

<script>
import EditDeviceModal from '@/components/modals/EditDeviceModal';
import StatusBadge from '@/components/ui/StatusBadge';

import { formatDistanceToNow } from 'date-fns';

export default {
  name: 'DevicePageHeader',
  components: {
    EditDeviceModal,
    StatusBadge
  },
  props: {
    device: {
      required: true,
      type: Object,
      default: null
    }
  },
  methods: {
    elapsedTimeAsRelative(date) {
      return date ? formatDistanceToNow(Date.parse(date)) : null;
    },
    getDeviceImage() {
      // If no modelName just don't show an image
      const deviceType = this.device?.info?.modelName ? this.device?.info?.modelName : '';

      // Just using one photo for both PAX devices as they're pretty similar
      switch (deviceType) {
        case 'PAX A920':
          return '/pax.png';
        case 'PAX A920 Pro':
          return '/pax.png';
        case 'SUNMI T2 Mini':
          return '/sunmi.png';
        default:
          return '';
      }
    },
    deviceUpdated() {
      this.$emit('deviceUpdated');
    }
  }
};
</script>
